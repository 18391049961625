import { Outlet, Link } from "react-router-dom";
import {Button, Heading} from '@chakra-ui/react'


const NoPage = () => {
  return (
    <>
      <Heading>This page does not exist.</Heading>
    </>
  )
};

export default NoPage;